/*
* RC Pagination
*/
.rc-pagination {
  @apply flex flex-wrap items-center space-x-3 space-y-3 rtl:space-x-reverse;
}

.rc-pagination .rc-pagination-item {
  @apply flex h-7 w-7 items-center justify-center rounded border border-border-base bg-gray-50 font-semibold hover:border-accent hover:text-accent focus:border-accent focus:text-accent;
}
.rc-pagination .rc-pagination-prev,
.rc-pagination .rc-pagination-next,
.rc-pagination.rc-pagination-simple .rc-pagination-prev,
.rc-pagination.rc-pagination-simple .rc-pagination-next {
  @apply mt-3 flex items-center text-sm capitalize text-accent;
}
/* .rc-pagination .rc-pagination-prev {
  @apply mr-8 rtl:ml-8;
}
.rc-pagination .rc-pagination-next {
  @apply ml-6 rtl:mr-6;
} */

.rc-pagination .rc-pagination-prev.rc-pagination-disabled,
.rc-pagination .rc-pagination-next.rc-pagination-disabled {
  @apply text-gray-300 hover:border-border-base hover:text-gray-300 focus:border-border-base;
}

.rc-pagination .rc-pagination-prev svg,
.rc-pagination .rc-pagination-next svg {
  @apply h-4 w-4 rtl:rotate-180 rtl:transform;
}

.rc-pagination .rc-pagination-item a {
  @apply font-body text-sm text-body;
}

.rc-pagination .rc-pagination-item:focus a,
.rc-pagination .rc-pagination-item:hover a {
  @apply text-accent;
}

.rc-pagination .rc-pagination-jump-prev button,
.rc-pagination .rc-pagination-jump-next button {
  @apply h-8 text-body-dark focus:outline-0 focus:ring-1 focus:ring-accent-300;
}

.rc-pagination .rc-pagination-item.rc-pagination-item-active {
  @apply border-accent bg-accent text-light;
}

.rc-pagination .rc-pagination-item.rc-pagination-item-active a {
  @apply text-light hover:text-light focus:text-light;
}

.rc-pagination .rc-pagination-options .rc-pagination-options-quick-jumper,
.rc-pagination.rc-pagination-simple .rc-pagination-simple-pager {
  @apply flex items-center font-body text-sm text-heading;
}

.rc-pagination .rc-pagination-options .rc-pagination-options-quick-jumper input,
.rc-pagination.rc-pagination-simple .rc-pagination-simple-pager input {
  @apply h-8 rounded border border-border-base bg-gray-50 px-2 font-body text-sm text-heading hover:border-accent focus:border-accent focus:outline-0;
}

/* RC Table */
.rc-table {
  @apply border-separate shadow-sm;
  border-spacing: 0;
}

.rc-table .rc-table-content,
.rc-table .rc-table-body,
.rc-table .rc-table-header {
  @apply border-0;
}

.rc-table thead.rc-table-thead th,
.rc-table-row-expand-icon-cell {
  padding: 1rem 0.5rem;
  font-family: system-ui,sans-serif;
  font-size: .875rem;
  line-height: 1.25rem;
  background-color: white !important;
  border: 1px solid rgb(var(--color-gray-200)) !important;
  border-bottom: none !important;
  /* border-top: none !important; */
}

.rc-table tr.rc-table-row td {
  border: 1px solid rgb(var(--color-gray-200)) !important;
  font-weight: 400;
  padding: 1rem 0.5rem;
  font-size: .875rem;
}

.rc-table tr.rc-table-row:hover {
  background-color: rgb(var(--color-gray-50)) !important;
}


.rc-table tr.rc-table-row:not(.rc-table-expanded-row) {
  cursor: pointer;
}

.rc-table tr.rc-table-expanded-row > td {
  border: 1px solid rgb(var(--color-gray-50)) !important;
  padding: 0px !important;

}

.rc-table tr.rc-table-expanded-row tr.rc-table-row {
  cursor: default;
}

.rc-table .rc-table-expanded-row-fixed::after {
  border-width: 0;
}

.rc-table-cell.rc-table-cell-row-hover {
  background-color: rgb(var(--color-gray-100)) !important;
}
.rc-table-cell-scrollbar{
  border: none !important
}
table, tbody, tfoot, thead, tr, th, td {
  vertical-align: middle !important;
}

/* RC Collapse */
.rc-collapse {
  background-color: #ffffff !important;
  border-radius: 0 !important;
  border: 0px solid #d9d9d9 !important;
}

.rc-collapse:focus {
  outline: none !important;
}

.rc-collapse > .rc-collapse-item {
  border: 0px solid #d9d9d9 !important;
}

.rc-collapse > .rc-collapse-item > .rc-collapse-header {
  padding: 0 !important;
}

.rc-collapse > .rc-collapse-item > .rc-collapse-header:focus {
  outline: none !important;
}

.rc-collapse-content {
  padding: 0 !important;
}

#headlessui-dialog{
background: #000000B2 !important;
}
/* button:disabled{
  background-color: rgb(var(--bg-disable)) !important;
  cursor: no-drop;
}

*/
.border-bottom {
  border-bottom: 1px solid #e3e6ea;
}
.order-shipping-cost-table-input:focus ,.order-shipping-cost-table-input:focus-visible{
  border: 1px solid rgb(var(--color-accent-300)) !important;
  outline: #fff !important;
}