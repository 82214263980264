// Override default variables before the import


@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-accent: 0, 159, 127;
    --bg-disable: 117, 189, 175;
    --border-accent-custom:176, 176, 177;
    --bg-accent-hover:1,147,118;

    /*
    * Hover state color of primary color
    */
    --color-accent-hover: 1, 147, 118;

    /*
    * shade of primary color
    */
    --color-accent-200: 204, 236, 229;

    /*
    * shade of primary color
    */
    --color-accent-300: 153, 217, 204;

    /*
    * shade of primary color
    */
    --color-accent-400: 77, 188, 165;

    /*
    * shade of primary color
    */
    --color-accent-500: 0, 159, 127;

    /*
    * shade of primary color
    */
    --color-accent-600: 0, 143, 114;

    /*
    * shade of primary color
    */
    --color-accent-700: 0, 119, 95;

    /*
    * light color
    */
    --color-light: 255, 255, 255;

    /*
    * dark color
    */
    --color-dark: 0, 0, 0;

    /*
    * Black muted color
    */
    --color-muted-black: 17, 17, 17;

    /*
    * Current color
    */
    --color-current: currentColor;

    /*
    *
    * TEXT colors
    *
    */

    /*
    * text color used for body/base text -> gray-500
    */
    --text-base: 107, 114, 128;

    /*
    * dark base text color  -> gray-600
    */
    --text-base-dark: 75, 85, 99;

    /*
    * muted text color -> gray-400
    */
    --text-muted: 156, 163, 175;

    /*
    * light muted text color - gray-300
    */
    --text-muted-light: 209, 213, 219;

    /*
    * text color used for heading text -> gray-700
    */
    --text-sub-heading: 55, 65, 81;

    /*
    * text color used for heading text -> gray-800
    */
    --text-heading: 31, 41, 55;

    /*
    * text color used for heading text -> gray-900
    */
    --text-bolder: 31, 41, 55;

    /*
    /*
    * border color 50
    */
    --color-border-50: 249, 250, 251;

    /*
    * border color 100
    */
    --color-border-100: 243, 244, 246;

    /*
    * border color 200
    */
    --color-border-200: 229, 231, 235;

    /*
    * border base color which is 300
    */
    --color-border-base: 209, 213, 219;

    /*
    * border color 400
    */
    --color-border-400: 0, 163, 175;

    /*
    *
    * Tailwind default gray color override
    *
    */

    /*
    * gray-50
    */
    --color-gray-50: 249, 250, 251;

    /*
    * gray-100
    */
    --color-gray-100: 243, 244, 246;

    /*
    * gray-200
    */
    --color-gray-200: 229, 231, 235;

    /*
    * gray-300
    */
    --color-gray-300: 209, 213, 219;

    /*
    * gray-400
    */
    --color-gray-400: 156, 163, 175;

    /*
    * gray-500
    */
    --color-gray-500: 107, 114, 128;

    /*
    * gray-600
    */
    --color-gray-600: 75, 85, 99;

    /*
    * gray-700
    */
    --color-gray-700: 55, 65, 81;

    /*
    * gray-800
    */
    --color-gray-800: 31, 41, 55;

    /*
    * gray-900
    */
    --color-gray-900: 31, 41, 55;

    /*
    * orange-50
    */
    --color-orange-50: 255, 247, 237;

    /*
    * orange-100
    */
    --color-orange-100: 255, 237, 213;

    /*
    * orange-200
    */
    --color-orange-200: 254, 215, 170;

    /*
    * orange-300
    */
    --color-orange-300: 253, 186, 116;

    /*
    * orange-400
    */
    --color-orange-400: 251, 146, 60;

    /*
    * orange-500
    */
    --color-orange-500: 249, 115, 22;

    /*
    * orange-600
    */
    --color-orange-600: 234, 88, 12;

    /*
    * orange-700
    */
    --color-orange-700: 194, 65, 12;

    /*
    * orange-800
    */
    --color-orange-800: 154, 52, 18;

    /*
    * orange-900
    */
    --color-orange-900: 124, 45, 18;

    /*
    Pending
    */
    --color-pending: 201, 161, 22;

    /*
    Processing
    */
    --color-processing: 158, 117, 0;

    /*
    Complete
    */
    --color-complete: 0, 161, 127;

    /*
    Canceled
    */
    --color-canceled: 227, 110, 1;

    /*
    Failed
    */
    --color-failed: 238, 43, 0;

    /*
    Out for delivery
    */
    --color-out-for-delivery: 126, 173, 66;
  }

  html {
    -webkit-tap-highlight-color: transparent;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    @apply font-body;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-heading;
  }

  a {
    @apply no-underline;
  }
}
@import './custom-plugins.css';



// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";
$font-size: 100;

@for $font from 1 through $font-size {
  .font-#{$font} {
    font-size: $font + px !important;
  }
}
@font-face {
  font-family: "madera";
  src: url("../public/fonts/madera-medium/monotype__-_madera_medium-webfont.woff2") format("woff2"),
    url("../public/fonts/madera-medium/monotype__-_madera_medium-webfont.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "madera";
  src: url("../public/fonts/madera-regular/monotype__-_madera_regular-webfont.woff2") format("woff2"),
    url("../public/fonts/madera-regular/monotype__-_madera_regular-webfont.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  font-family: "madera";
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-black {
  color: #000;
}

.text-gray {
  color: #868686;
}

.border-bottom {
  border-bottom: 1px solid #e3e6ea;
}

.btn {
  font-size: 18px;
  height: 50px;
  border-radius: 0 !important;
  min-width: 195px;
  font-weight: 400;
}

.modal-header {
  border: none !important;
  // padding-bottom: 0 !important;
}

.modal-body {
  padding-top: 0 !important;
}

input {
  border: 1px solid #e3e6ea;
  padding: 6px 10px;
  margin-top: 2px !important;
  height: 46px;
  line-height: 46px;
}

label {
  color: #000000;
  font-weight: 400;
  font-size: 14px;
  // text-transform: uppercase;
}

.wrapper .btn-primary,
.modal-dialog .btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #000;
  --bs-btn-border-color: #000;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #000;
  --bs-btn-hover-border-color: #000;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #000;
  --bs-btn-active-border-color: #000;
  --bs-btn-active-shadow: inset 0 3px 5pxrgba (0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #000;
  --bs-btn-disabled-border-color: #000;
}

.wrapper .btn-outline-primary,
.modal-dialog .btn-outline-primary {
  --bs-btn-color: #000;
  --bs-btn-border-color: #000;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #000;
  --bs-btn-hover-border-color: #000;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #000;
  --bs-btn-active-border-color: #000;
  --bs-btn-active-shadow: inset 0 3px 5pxrgba (0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #000;
  --bs-gradient: none;
}

.section {
  &.banner-section {
    background-color: #f4f6f8;
  }

  .title {
    text-transform: uppercase;
    font-size: 24px;
    color: #000000;
    font-weight: 500;
  }

  .sub-title {
    font-size: 18px;
    color: #000000;
    font-weight: 400;
    width: 90%;
    margin: 0 auto;
  }

  .box {
    width: 250px;
    height: 189px;
    position: relative;

    .badge-img {
      width: 250px;
      height: 189px;

      &.active {
        border: 2px solid #000;
      }
    }

    .badge {
      background-color: #22201f;
      color: #fff;
      font-size: 13px;
      padding: 0px 20px;
      position: absolute;
      bottom: 13px;
      left: 50%;
      right: 0;
      display: inline-table;
      width: unset;
      border-radius: 17px;
      height: 34px;
      line-height: 36px;
      font-weight: 400;

      transform: translateX(-50%);
      @media screen and (max-width: 1024px) {
        position: relative;
        left: 0;
        right: 0;
        margin-top: 20px;
        transform: unset;
      }
      @media screen and (max-width: 765px) {
        font-size: 20px;
        background-color: transparent;
        color: #000;
      }
    }
  }

  &.product-info {
    padding-top: 30px;

    .product-info-box {
      width: 107px;
      height: 107px;
      border: 1px solid #e3e6ea;
      border-radius: 2px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        object-fit: contain;
        width: 100%;
        height: auto;
      }

      &.active {
        border: 2px solid #000;
      }
    }

    .product-title {
      text-transform: uppercase;
      padding: 15px 0;
      border-top: 1px solid #e3e6ea;
      border-bottom: 1px solid #e3e6ea;
      font-size: 21px;
      margin-top: 30px;
    }

    .product-info-row {
      .left-part {
        img {
          width: 413px;
          height: 413px;
        }
      }

      .right-part {
        ul {
          border-left: 1px solid #e3e6ea;
          border-right: 1px solid #e3e6ea;
          padding-left: 0;
          margin: 0;
          min-height: 60vh;
          li {
            height: 110px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 91px;
            border-bottom: 1px solid #e3e6ea;
            cursor: pointer;

            .close-icon {
              cursor: pointer;
            }

            img {
              opacity: 0.5;
            }

            .tab-text {
              font-size: 13px;
              font-weight: 400;
              padding: 0 7px;
              text-transform: uppercase;
              color: #717171;
            }

            &.active {
              border: 1px solid black;
              color: black;

              img {
                opacity: 1;
              }

              .tab-text {
                color: #000000;
              }
            }
          }
        }

        .small-box {
          width: 50px;
          height: 50px;
          border: 1px solid #e3e6ea;
          border-radius: 2px;
          cursor: pointer;

          img {
            width: 100%;
          }
        }

        .swatch-box {
          // border: 1px solid #e3e6ea;
          border-radius: 2px;
          cursor: pointer;
          padding: 5px;
          min-width: 75px;
          text-align: center;
          border: 1px solid #e3e6ea;

          &.active {
            border: 1px solid #000;
          }

          .size-26 {
            width: 65px;
            height: 65px;

            &img {
              width: 100%;
            }
          }

          // .size-24 {
          //     width: 85px;
          //     height: 85px;

          //     &img {
          //         width: 100%;
          //     }
          // }

          // .size-22 {
          //     width: 75px;
          //     height: 75px;

          //     &img {
          //         width: 100%;
          //     }
          // }

          // .size-20 {
          //     width: 72px;
          //     height: 72px;

          //     &img {
          //         width: 100%;
          //     }
          // }

          // .size-18 {
          //     width: 65px;
          //     height: 65px;

          //     &img {
          //         width: 100%;
          //     }
          // }

          // .size-16 {
          //     width: 55px;
          //     height: 55px;

          //     &img {
          //         width: 100%;
          //     }
          // }

          // .size-14 {
          //     width: 100px;
          //     height: 60px;
          // }

          // .size-12 {
          //     width: 80px;
          //     height: 45px;
          // }

          .swatch-size {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 0;
          }
        }

        .side-swatch {
          width: 85px;
          height: 85px;
          padding: 5px;
        }

        .side-box {
          border-radius: 2px;
          cursor: pointer;
          padding: 11px;
          min-width: 120px;
          text-align: center;
          border: 1px solid #e3e6ea;
          align-items: center;
          display: flex;
          flex-direction: column;
          &.active {
            border: 1px solid #000;
          }

          .side-img {
            width: 53px;
            // margin: 5px;
          }
        }

        .control-wrapper {
          .full-search-bar {
            max-width: 100% !important;
          }

          .form-group {
            max-width: 44%;
            width: 100%;

            .search-control {
              width: 100%;
              height: 45px;
              padding: 5px 10px;
              font-size: 12px;
              border: 1px solid #e3e6ea;
              outline: none;
              color: #717171;
              font-weight: normal;
              max-width: 256px;
            }

            .search-icon {
              position: absolute;
              right: 0;
              top: 53%;
              transform: translate(-150%, -50%);
            }
          }

          .filter-wrapper {
            cursor: pointer;
            max-width: 36%;
            width: 100%;

            p {
              width: 100%;
              height: 45px;
              padding: 5px 10px;
              font-size: 12px;
              border: 1px solid #e3e6ea;
              outline: none;
              color: #717171;
              margin-bottom: 0;
              line-height: 34px;
              white-space: nowrap;
              font-weight: normal;
            }
          }
          .item-choose {
            .active {
              position: relative;

              img {
                border: 2px solid #3ddbd4;
              }

              &:after {
                content: "";
                position: absolute;
                right: -8px;
                top: -7px;
                width: 23px;
                height: 23px;
                text-align: center;
                background-image: url("./../public/Tick1.svg");
              }
            }

            img {
              width: 75px;
              height: 75px;
              object-fit: cover;
            }

            .item-choose-wrapper {
              height: 280px;
              overflow: hidden;
              width: 300px;
              overflow-y: scroll;

              &::-webkit-scrollbar {
                width: 8px;
              }

              &::-webkit-scrollbar-track {
                border-radius: 8px;
                background-color: #00000008;
              }

              &::-webkit-scrollbar-thumb {
                border-radius: 8px;
                background-color: #00000024;
              }
            }
          }
        }

        .pattern-wrapper {
          ul {
            border: none;
            min-height: fit-content;
          }

          li {
            width: auto !important;
            height: auto !important;
            display: inline-block !important;
            border: 1px solid #e3e6ea;
            padding: 5px 10px;
            margin-right: 10px;
            margin-bottom: 10px;
            font-size: 12px;
            color: #717171;
          }
        }

        .color-fiilter-box {
          width: 44px;
          height: 44px;
          border-radius: 2px;
          cursor: pointer;
          margin-right: 8px;
          margin-bottom: 8px;
          object-fit: cover;

          img {
            width: 45px;
            object-fit: cover;
            height: 45px;
          }

          &.active {
            position: relative;

            &:after {
              content: "";
              position: absolute;
              right: 0;
              top: 0;
              width: 18px;
              height: 18px;
              text-align: center;
              border: 1px solid #ffffff;
              background: #ffffff;
              border-radius: 50%;
              line-height: 14px;
              color: #717171;
              left: 0;
              bottom: 0;
              text-align: center;
              margin: auto;
              font-size: 8px;
              background-image: url("./../public/Tick1.svg");
            }
          }
        }
      }
    }
  }

  &.product-summary {
    padding-top: 60px;
    margin-bottom: 180px;
    border-top: 1px solid #e3e6ea;

    .cart-item {
      .cart-item-img {
        min-width: 150px;
        width: 150px;
        height: 150px;
        box-shadow: 0px 0px 15px #00000029;
        border: 1px solid #f1f1f1;

        img {
          object-fit: contain;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: auto;
          padding: 10px;
        }
      }

      .cart-item-name {
        color: #3ddbd4;
        font-size: 18px;
        letter-spacing: 0px;
      }
    }

    .qty-rmv {
      display: flex;
      flex-direction: column;
      align-items: center;

      .quantity {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
      }

      .quantity__minus,
      .quantity__plus {
        display: block;
        width: 43px;
        height: 55px;
        margin: -10px;
        text-decoration: none;
        text-align: center;
        line-height: 50px;
        border: 1px solid #f1f1f1;
      }

      .quantity__minus {
        border-radius: 3px 0 0 3px;
        border-right: 0;
      }

      .quantity__plus {
        border-radius: 0 3px 3px 0;
        border-left: 0;
      }

      .quantity__input {
        width: 40px;
        height: 55px;
        margin: 2px;
        padding: 0;
        text-align: center;
        outline: none;
        border-top: 1px solid #f1f1f1;
        border-bottom: 1px solid #f1f1f1;
        border-left: 0;
        border-right: 0;
        background: #fff;
        color: #000;
        font-size: 20px;
      }

      .quantity__minus:link,
      .quantity__plus:link {
        color: #868686;
        font-size: 22px;
      }

      .quantity__minus:visited,
      .quantity__plus:visited {
        color: #868686;
      }

      .remove-text {
        color: #3ddbd4;
        font-size: 16px;
        line-height: 18px;
        margin-top: 10px;
        text-decoration: none;
      }
    }

    .price {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .price-text {
        color: #3ddbd4;
        font-size: 22px;
        line-height: 25px;
        margin-top: 10px;
      }
    }

    .subtotal-amnt {
      border-top: 1px solid #e3e6ea;

      .subtotal-text {
        color: #000000;
        font-weight: 400;
        font-size: 24px;
      }

      .subtotal-price {
        color: #3ddbd4;
        font-weight: 400;
        font-size: 22px;
      }
    }

    .btn-mutiple {
      margin-top: 54px;
    }

    .qty-rmv {
      align-items: center;
      margin-top: 10px;
    }
  }

  &.footer {
    background-color: #f4f6f8;
    padding: 30px 0;

    .social-links {
      margin-bottom: 30px;

      ul,
      li {
        display: inline-block;
        vertical-align: middle;
        list-style: none;
        margin: 0;
        padding: 0;
        line-height: 15px;

        a {
          margin: 0 4px;
          width: 49px;
          border-radius: 100%;
          height: 49px;
          border: 1px solid #ccc;
          background: #fff;
          text-align: center;
          max-width: 100%;
          align-items: center;
          display: flex;

          img {
            margin: 0 auto;
            vertical-align: middle;
            max-width: 100%;
            height: auto;
          }
        }
      }
    }

    .copyright {
      color: gray;

      a {
        text-transform: uppercase;
        color: gray;
        margin: 0px 10px;
      }
    }
  }
}

.save-modal {
  .modal-dialog {
    max-width: 674px;
  }
}

.project-saved-modal {
  .modal-dialog {
    max-width: 904px;

    .modal-ty {
      width: 200px;
    }

    .modal-item {
      width: 250px;
    }

    .copy-link {
      color: #717171;
      font-size: 16px;
      font-weight: 400;
      cursor: not-allowed;
    }

    .preview-link {
      color: #000;
      font-size: 16px;
      font-weight: 400;
    }
  }
}

.modal-dialog {
  min-height: calc(100% - (1.75rem * 2));

  .modal-content {
    border-radius: 0;

    .btn {
      min-width: 150px;
    }

    .btn-close {
      opacity: 1;
      width: 2em;
      height: 2em;
    }

    .modal-body {
      width: 68%;
      margin: 0 auto;
      margin-bottom: 55px;

      h2.title {
        color: #000000;
        font-size: 20px;
        font-weight: 500;
        text-transform: uppercase;
      }

      h5.sub-title {
        color: #000000;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .m-font {
    font-size: 13px;
  }
  .mobile-none {
    display: none;
  }
  .mobile-shadow {
    overflow: hidden;
  }
  .product-info-row.container {
    max-width: 100%;
  }
  .size-selected,
  .swatch-size {
    display: none !important;
  }
  .mobile-shadow {
    box-shadow: 0px -8px 10px #00000017;
  }
  .section.product-info .product-info-row .right-part ul {
    border-left: 0;
    border-right: 0;
    min-height: inherit;
  }
  .section.product-info .product-info-box img {
    width: 100%;
    height: 80px;
  }
  .section .box .badge-img {
    width: 250px;
    height: 140px !important;
  }
  .font-14 {
    font-size: 12px !important;
  }
  .section.product-info .product-info-row .right-part .control-wrapper .form-group .search-control,
  .section.product-info .product-info-row .right-part .control-wrapper .filter-wrapper,
  .section.product-info .product-info-row .right-part .control-wrapper .form-group {
    max-width: none;
  }
  .tab-item-wrapper {
    flex-wrap: wrap;
    width: 100%;
    border: none !important;

    ul {
      order: 2;
      display: flex;
      width: 100%;

      li {
        width: 45% !important;
      }
    }

    .item-infos {
      order: 1;
    }
  }

  .section.product-summary .qty-rmv {
    align-items: flex-start;
  }

  .product-info {
    .product-row {
      overflow: hidden;
      overflow-x: auto;
      width: 96.5vw !important;
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      max-width: 100%;
      // align-items: flex-start !important;
      // justify-content: start !important;

      .product-info-box {
        width: auto;
        min-width: 80px;
        height: auto;
        margin-right: 0.4rem !important;
        margin-left: 0.4rem !important;
        justify-content: center !important;
      }
    }
  }

  .section {
    .title {
      font-size: 24px;
    }

    .sub-title {
      font-size: 16px;
    }

    .box {
      width: auto;
      height: auto;
      margin: 0 9px !important;

      .badge {
        font-size: 13px;
        // padding: 10px 12px;
        height: 25px;
        line-height: 20px;
      }
    }
  }

  .badge-img {
    width: 250px;
    height: 100px !important;
    object-fit: cover;
  }

  .product-title {
    font-size: 18px !important;
  }

  .sizes-mobile {
    overflow: hidden;
    overflow-x: auto;
    width: 96.5vw !important;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    max-width: 100%;
    padding-bottom: 33px;
  }

  .product-info-row {
    .item-infos {
      margin-right: 0 !important;
    }

    .left-part {
      img {
        width: 413px !important;
        height: auto !important;
      }
    }

    .right-part {
      .swatch-box {
        min-width: 80px !important;
        margin-right: 0.7rem !important;

        .swatch-size {
          font-size: 14px !important;
        }

        img {
          width: 65px !important;
          height: 65px !important;
          min-width: 65px !important;
        }
      }

      .tab-content {
        .p-4 {
          padding-left: 0 !important;
          padding-right: 0 !important;
          padding-bottom: 0 !important;
        }
      }

      ul {
        border-left: 0;
        border-right: 0;

        li {
          height: 90px;

          .tab-text {
            font-size: 15px !important;
          }

          img {
            opacity: 0.5;
            width: 40px;
            height: 40px;
            object-fit: contain;
          }
        }
      }

      .item-choose-wrapper {
        height: 140px !important;
        overflow: hidden !important;
        overflow-x: auto !important;
        width: 96.5vw !important;
        flex-wrap: nowrap !important;
        margin-bottom: 10px;

        &::-webkit-scrollbar {
          height: 2px !important;
        }

        .item-choose img {
          width: 70px !important;
          height: 70px !important;
          object-fit: cover;
          min-width: 70px !important;
        }
      }

      .side-swatch {
        width: 70px !important;
        height: 70px !important;
      }
    }
  }

  .product-summary {
    .price-text {
      font-size: 16px !important;
      line-height: 16px !important;
    }

    .cart-item-name {
      font-size: 16px !important;
    }

    .subtotal-text,
    .subtotal-price {
      font-size: 22px;
    }
  }

  .btn {
    font-size: 16px;

    min-width: 150px;
    font-weight: 400;
  }

  .modal-dialog .modal-content .modal-body {
    width: 100%;
  }

  .filter-modal .modal-dialog {
    max-width: 85%;
  }
}

.mobile-size {
  height: 75px;
  width: 75px;
}
@media screen and (max-width: 767px) {
  .m-font {
    font-size: 13px;
  }
  .mobile-scroll {
    height: 140px !important;
    overflow: hidden !important;
    overflow-x: auto !important;
    width: 93.5vw !important;
    flex-wrap: nowrap !important;
    margin-bottom: 10px;
  }
  .section.product-info .product-info-row .right-part .side-box {
    min-width: 90px;
  }
  .mobile-shadow {
    box-shadow: 0px -8px 10px #00000017;
  }
  .section .box .badge-img {
    width: 250px;
    height: 76px !important;
  }
  .size-selected,
  .swatch-size {
    display: none !important;
  }
  .tab-item-wrapper {
    flex-wrap: wrap;
    width: 100%;
    border: none !important;

    ul {
      order: 2;
      display: flex;
      width: 100%;

      li {
        width: 45% !important;
      }
    }

    .item-infos {
      order: 1;
    }
  }

  .label-input {
    flex: auto !important;
    margin-bottom: 12px;
  }

  .section.product-summary .qty-rmv {
    align-items: flex-start;
    padding-left: 10px;
  }

  .product-info {
    .product-row {
      overflow: hidden;
      overflow-x: auto;
      width: 93.5vw !important;
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      max-width: 100%;
      // align-items: flex-start !important;
      justify-content: start !important;
      padding-bottom: 20px;

      .product-info-box {
        width: 105px;
        min-width: 80px;
        height: auto;
        margin-right: 0.4rem !important;
        margin-left: 0.4rem !important;
        justify-content: flex-start !important;
      }
    }
  }

  .section {
    .title {
      font-size: 22px;
    }

    .sub-title {
      font-size: 14px;
    }

    .box {
      width: auto;
      height: auto;
      margin: 0 7px !important;

      .badge {
        font-size: 15px;
        // padding: 0 10px;
        // height: 15px;
        // line-height: 13px;
      }
    }
  }

  .badge-img {
    width: 250px;
    height: auto !important;
  }

  .product-title {
    font-size: 16px !important;
  }

  .product-info-row .left-part img {
    width: 250px;
  }

  .sizes-mobile {
    overflow: hidden;
    overflow-x: auto;
    width: 93.5vw !important;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    max-width: 100%;
    padding-bottom: 33px;
  }

  .product-info-row {
    .item-infos {
      margin-right: 0 !important;
    }

    .left-part {
      img {
        width: 100% !important;
        height: auto !important;
      }
    }

    .right-part {
      .fabric-input-wrapper {
        width: 300px;
      }
      .fabric-block .item-choose img {
        width: 64px !important;
        height: 64px !important;
      }

      .swatch-box {
        min-width: 73px !important;

        .swatch-size {
          font-size: 12px !important;
        }

        img {
          width: 52px !important;
          height: 53px !important;
          min-width: 53px !important;
        }
      }

      .tab-content {
        .p-4 {
          padding-left: 0 !important;
          padding-right: 0 !important;
          padding-bottom: 0 !important;
        }
      }

      ul {
        border-left: 0;
        border-right: 0;

        li {
          height: 90px;

          .tab-text {
            font-size: 12px !important;
          }

          img {
            opacity: 0.5;
            width: 30px;
            height: 30px;
            object-fit: contain;
          }
        }
      }

      .item-choose-wrapper {
        height: 140px !important;
        overflow: hidden !important;
        overflow-x: auto !important;
        width: 93.5vw !important;
        flex-wrap: nowrap !important;
        margin-bottom: 10px;

        &::-webkit-scrollbar {
          height: 2px !important;
        }

        .item-choose img {
          width: 65px !important;
          height: 65px !important;
          object-fit: cover;
          min-width: 65px !important;
        }
      }

      .side-swatch {
        width: 65px !important;
        height: 65px !important;
      }
    }
  }

  .product-summary {
    .price .price-text {
      font-size: 14px !important;
      line-height: 14px !important;
    }

    .cart-item-name {
      font-size: 14px !important;
    }
  }

  .modal-dialog .modal-content .modal-body {
    width: 100%;
  }

  .filter-modal .modal-dialog {
    max-width: 100%;
  }
}
.size-selected {
  width: 260px;
}
.sizes-desktop {
  width: 400px;
}

.mw-88 {
  min-width: 80% !important;
}
.maxw-100 {
  max-width: 100% !important;
}
.mobile-item .item-choose {
  width: 20%;
}
.cursor-pointer {
  cursor: pointer;
}

.green-color {
  color: #4cded7;
}

.drop-zone {
  border: 2px dashed #e3e6ea;
  height: 250px;
  // width: 400px;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  color: #a1a1a1;
}

.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #a1a1a1;
}

.separator:not(:empty)::before {
  margin-right: 0.25em;
}

.separator:not(:empty)::after {
  margin-left: 0.25em;
}
.reset-input-style {
  height: unset !important;
  margin: 0 !important;
}

.w-min-126px {
  min-width: 126px !important;
}
.add-fabric-button {
  padding: 20px;
  border: 1px dashed #717171;
  background: #f8f8f8;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
}
.break-word {
  word-wrap: break-word;
}
.w-90 {
  width: 90% !important;
}
.active-category {
  border: 1px solid #000 !important;
}

.fabric-box {
  p {
    // white-space: nowrap;
    overflow: hidden;
    // text-overflow: ellipsis;
    // width: 4rem;
    width: 75px;
  }
}
.color-gray {
  color: #717171;
}

.border-gray {
  border: 1px solid #e3e6ea;
}
.border-top-gray {
  border-top: 1px solid #e3e6ea;
}
.two-line-45px-text-box {
  width: auto;
  height: 45px !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.crop-button {
  background-color: rgba(134, 134, 134, 0.1) !important;
}

.repeat-validation,
.repeat-validation:focus-visible,
.repeat-validation:focus {
  border: 1px solid red !important;
  outline: none !important;
}
.search-control:focus-visible,
.search-control:focus,
.reset-input-style:focus-visible,
.reset-input-style:focus {
  border: 1px solid black !important;
  outline: none !important;
}
.Customization-drawer .ant-drawer-body {
  padding: 0 !important;
  overflow-x: hidden !important;
}

.mb-halfrem {
  margin-bottom: 0.5rem !important;
}
.ant-input-number {
  border-radius: 0 !important;
}
.pl-0 {
  padding-left: 0 !important;
}

.crop-action {
  width: 60px;
  min-width: 60px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.f-18 {
  font-size: 18px !important;
}
// .ant-drawer-header {
//   display: none !important;
// }

.ant-input-number:focus,
.ant-input-number:hover,
.ant-input-number:active,
.ant-input-number:visited,
.ant-input-number:focus-visible, 
.ant-input-number input
 {
  border-color: #000 !important; 
  box-shadow: none !important;
  outline: none !important;
}

.auth-page {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pwd-input{
  height: 50px;
  width: 400px;
}

@media (max-width: 576px) {
  .auth-page {
      flex-direction: column;
      input{
        width: 90% !important
      }
  }
}
.order-customer-detail-div{
  @media (min-width:767px) {
    border-right:1px solid #dee2e6;
  }
}
.order-pricing-row{
  border-bottom: 1px solid rgba(229, 231, 235);
}

.order-status-row {
  border: 1px solid rgba(229, 231, 235);
  border-top: none
}

.order-status-border-right {
  border-right: 1px solid rgba(229, 231, 235);
}

.order-status-modal {
  @media (min-width: 768px) {
    .modal-dialog {
      max-width: 750px;
    }
  width: 100vw !important;
}
}
.cropper-preview-image .cropper-drag-box {
  display: none !important;
}
.cropper-preview-image .cropper-move {
  background-color: red !important;
  opacity: 0.4;
}

.delete-product-model {
  .modal-header .btn-close {
    margin-left: 0; // Remove left margin for close button
  }
}

.delete-header-border{
  border-bottom: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color) !important
}
.ant-picker-dropdown {
  z-index: 9999 !important;
}
input div[pseudo="-webkit-datetime-edit"]{
  display: none !important;
}

.select-input input {
  --tw-ring-color: none !important
}