.choose-card.active:after {
    content: "";
    position: absolute;
    right: -8px;
    top: -7px;
    width: 23px;
    height: 23px;
    text-align: center;
    background-image: url("../../../public/Tick1.svg");
}

.choose-card {
    border: 2px solid #3ddbd4;
    position: relative;
}

.p5Canvas {
    display: none !important;
}

.ant-slider-track {
    background-color: #3ddbd4 !important;
}

.ant-slider-handle::after {
    background-color: #3ddbd4 !important;
    box-shadow: 0 0 0 2px #3ddbd4 !important;
}

.margin0-16 {
    margin: 0 16px;
}

.pillow-band-type {
    height: 75%;
    max-width: 53px;
    width: 100%;
    margin: 0 auto;
}

.auto-border-sofa {
    border: 2px solid transparent;
}

.summary-header-card {
    background-color: rgb(244, 246, 248);
}

.error-email {
    border: 1px solid red;
}

.fw-400 {
    font-weight: 400 !important;
}

.text-red {
    color: red !important;
}

.fs-20 {
    font-size: 20px !important;
}

.fs-16 {
    font-size: 16px !important;
}

.width-33 {
    width: 33.33% !important;
}

.box-shadow-none {
    box-shadow: none !important;
}

.vintage-summary-first-part {
    width: 37%;
}

.vintage-summary-second-part {
    width: 63%;
}

.quantity-box {
    height: 40px;
    border: 1px solid #f1f1f1;
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-left: 8%;
    margin-right: 8%;
    user-select: none;
}

.my-spin {
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .ant-spin-dot {
        .ant-spin-dot-item {
            color: black !important;
            background-color: black !important;
        }
    }
}

.fabric-spin {
    display: flex;

    .ant-spin-dot {
        .ant-spin-dot-item {
            color: black !important;
            background-color: black !important;
        }
    }
}

.submit-loader {
    margin-left: 15px;

    .ant-spin-dot {
        .ant-spin-dot-item {
            background-color: white !important;
        }
    }
}
.project-save-submit-loader {
    margin-left: 15px;

    .ant-spin-dot {
        .ant-spin-dot-item {
            background-color: black !important;
        }
    }
}

.ant-drawer-content-wrapper {
    width: 100% !important;
    height: 100% !important;
}

#box {
    left: 546px;
    top: 281px;
    width: 179.2px;
    height: 179.2px;
    display: block;
    // display: none;
    /* Hide the box by default */
    position: absolute;
    // top: 0;
    // left: 0;
    background-color: rgba(255, 0, 0, 0.3);
    z-index: 1;
}

#container {
    position: relative;
    display: flex;
    // justify-content: center;
    align-items: center;
}

#finish_image {
    /* width: 100%;
    height: 100%; */
    object-fit: contain;
    user-select: none;
}

@media (max-width: 992px) {

    .vintage-summary-first-part,
    .vintage-summary-second-part {
        width: 50% !important;
    }
}

@media (max-width: 2560px) {
    .band-side-box {
        margin-top: 0;
    }
}

@media (max-width: 1400px) {
    .band-side-box {
        margin-top: 1rem;
    }
}

@media (max-width: 992px) {
    .band-side-box {
        margin-top: 0rem;
    }
}

#edit_image1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 50px;
    opacity: 1;
    border: 1px solid #000000;
    cursor: all-scroll
}

.image-container {
    position: relative;
}

.parent {
    width: 300px;
    height: 300px;
    border: 1px solid #000;
    box-sizing: border-box;
    background: red;
}

.draggable {
    width: 20px;
    height: 20px;
    border: 1px solid #000;
    border-radius: 50%;
    box-sizing: border-box;
    position: absolute;
    cursor: move;
    background: green;
}

// #container {
//     // height: 500px;
//     // width: 500px;
//     // border: 1px solid black;
//     // position: relative;
// }
#dragElement {
    height: 50px;
    width: 50px;
    background: red;
    position: absolute;
    cursor: move;
}

.progress-box_progress_container__\+sMn7 {
    width: 12% !important;
}


.text-col-progress {
    background-color: #fff !important;
    color: #000 !important
}

.text-col-progress-check {
    background-color: #00A698 !important;
    color: #fff !important
}

.progress-check-back {
    background-color: #00A698 !important;
}

input[type="date"] {
    position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
}

.bold-header-table th {
    font-weight: bold;
    color: #212529
}

.text-custom-ellipse {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
}

.order-status-select .css-pju5hx-Control {
    height: 40px;
}

.order-status-select .css-rw6mwp-Control {
    height: 40px;
}

// .order-status-select .css-lgb2dq-option {
//     height: 40px;
// }

.modal-fullscreen {
    max-width: none !important;
}
.table-cell{
    border: 1px solid rgb(var(--color-gray-200))!important;
    padding: 7px;
    font-size: .875rem;
    font-weight: 400;
}
tr{
    transition: all 0.2s;
}
tr:hover{
    background-color: rgb(var(--color-gray-100)) !important;
}