.progress_container {
  @apply mb-7 flex shrink-0 items-center last:mb-0 md:mb-0 md:w-1/3 md:flex-col lg:w-1/4 2xl:w-1/5;
}

.progress_container:only-child {
  @apply md:mx-auto;
}

.bar {
  @apply absolute -top-1/2 h-double w-1 bg-gray-100 left-1/2 -ml-px rtl:right-1/2 rtl:mr-px md:top-1/2 md:-mt-px md:h-1 md:w-full md:left-0 rtl:md:right-0;
}

.progress_container:only-child .bar {
  @apply top-1/2 hidden h-full w-1 md:mx-auto md:h-1 md:w-1/2 md:left-1/2 rtl:md:right-1/2;
}

.progress_container:first-child .bar {
  @apply top-1/2 h-full w-1 md:h-1 md:w-1/2 md:left-1/2 rtl:md:right-1/2;
}

.progress_container:last-child .bar {
  @apply -top-1/2 h-full w-1 md:top-1/2 md:h-1 md:w-1/2;
}

.progress_wrapper {
  @apply relative flex items-center justify-center md:mb-4 md:w-full;
}

.checked {}

.progress_wrapper.checked .bar {
  @apply bg-accent;
}

.status_wrapper {
  @apply z-10 flex h-9 w-9 items-center justify-center rounded-full border border-dashed border-gray-500 bg-light text-sm font-bold text-red-600;
}

.progress_wrapper.checked .status_wrapper {
  @apply bg-red-600 text-white;
}